@charset "utf-8";

a {
  &:hover {
    outline: none;
  }
  &:active {
    outline: none;
  }
  &:focus {
    outline: none;
  }
}
@media screen and (min-width: $display-width-s){
  /* =================================
    hover
  ================================= */
  a {
    @include animation-base(all);
    &:hover {
      text-decoration: none;
      color: $accent-color;
    }
  }
  .widelink {
    cursor: pointer;
    .widelink__cover {
      position: relative;
      overflow: hidden;
      &::before {
        content: "詳しく見る";
        position: absolute;
        top: 45%;
        left: 50%;
        z-index: 6;
        opacity: 0;
        color: white;
        text-align: center;
        line-height: 1.3;
        transition-property: opacity,top;
        transition-duration: 0.15s;
        transition-timing-function: ease-out;
        transform: translate(-50%, -50%);
      }
      &::after {
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        z-index: 5;
        opacity: 0;
        background-color: rgba(0, 0, 0, 0.5);
        color: white;
        transition-property: opacity;
        transition-duration: 0.15s;
        transition-timing-function: ease-out;
      }
    }
    &:hover {
      a {
        text-decoration: none;
        color: $accent-color;
      }
      .widelink__cover {
        &::before {
          opacity: 1;
          top: 50%;
        }
        &::after {
          opacity: 1;
        }
      }
    }
  }
  .hoverBg {
    .widelink__cover {
      &::before {
        content: none;
      }
      &::after {
        content: none;
      }
    }
    &:hover {
      background: #ebf4fc;
    }
  }
}