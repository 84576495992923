@charset "utf-8";

/* =================================
  common
================================= */
/*----------------------------------
  background
----------------------------------*/
body {
  background-color: $base-color;
}

@media screen and (max-width: $display-width-s){
}