@charset "utf-8";

/*=================================
  footer
=================================*/
.footer {
  background: #f9f5ea;
  margin-top: 134px;
  &__inner {
    position: relative;
    max-width: $base-width;
    margin: 0 auto;
    padding: 40px 0 30px;
  }
  .fnav {
    display: flex;
    &__area {
      width: calc(100% - 260px);
      &__list {
        display: flex;
        flex-wrap: wrap;
        &__item {
          width: 22%;
          margin: 20px 20px 0 0;
          .ttl--check--blue {
            font-size: 1.4rem;
            font-weight: bold;
            margin-bottom: 10px;
          }
          .listStyle--dots {
            margin-left: 1em;
            li {
              line-height: 1;
              & + li {
                margin-top: 7px;
              }
            }
          }
        }
      }
    }
    &__cont {
      width: 260px;
      margin-left: 20px;
      &__list {
        margin: 15px 0 0;
      }
    }
  }
  .copy {
    background: #f0e9d7;
    display: block;
    text-align: center;
    font-size: 1.4rem;
    line-height: 1;
    padding: 20px 2%;
  }
  .ttl--circle--lg {
    font-size: 2rem;
  }
  .pageTop {
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 1;
    text-decoration: none;
    padding: 45px 32px 80px;
    background: #f9f5ea;
    border-radius: 50%;
    position: absolute;
    top: -60px;
    left: 50%;
    transform: translateX(-50%);
    transition: all 0s;
    z-index: -1;
    &::before {
      content: "";
      border-style: solid;
      border-width: 0 12.5px 15px 12.5px;
      border-color: transparent transparent $font-color-link transparent;
      display: inline-block;
      position: absolute;
      top: 15px;
      left: 50%;
      transform: translateX(-50%);
    }
    &:hover::before {
      border-color: transparent transparent $accent-color transparent;
    } 
  }
}
@media screen and (max-width: $display-width-s){
  /*=================================
    footer
  =================================*/
  .footer {
    background: #fff;
    margin-top: 60px;
    &__inner {
      padding: 20px 0 10px;
    }
    .fnav {
      display: block;
      &__area {
        display: none;
      }
      &__cont {
        width: 100%;
        margin-left: 0;
        &__list {
          margin: 10px 0 0;
          display: flex;
          flex-wrap: wrap;
          &__item {
            width: 50%;
            padding: 12px 10px;
            border-bottom: 1px dashed #6c9ac6;
            &:nth-child(2n - 1) {
              border-right: 1px dashed #6c9ac6;
            }
            &:nth-child(-n + 2) {
              border-top: 1px dashed #6c9ac6;
            }
          }
        }
      }
    }
    .copy {
      background: #fff;
      font-size: 1rem;
      padding: 15px 0;
    }
    .ttl--circle--lg {
      font-size: 2rem;
      display: none;
    }
    .pageTop {
      font-size: 1.4rem;
      padding: 0;
      background: #fff;
      border-radius: 0;
      top: -15px;
      &::before {
        border-width: 0 6px 7px 6px;
        top: -10px;
      }
    }
  }
}