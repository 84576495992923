@charset "utf-8";

/* =================================
  header
================================= */
#top {
  .gNav {
    background: #fff;
  }
}
.header a {
  text-decoration: none;
}
.gNav {
  margin: 0 0 30px;
  padding: 10px 75px;
  background: #f8f5ee;
  &__list {
    max-width: $base-width;
    margin: 0 auto;
    display: table;
    width: 100%;
  }
  .gNav__list__item {
    display: table-cell;
    width: 25%;
    font-size: 2rem;
    font-weight: bold;
    line-height: 1;
    color: $font-color-link;
    text-align: center;
    position: relative;
    z-index: 20;
    a {
      padding: 6px 25px;
      border-right: 1px solid #b5b5b5;
      display: block;
    }
  }
  .gNav__list__item:first-child a {
    border-left: 1px solid #b5b5b5;
  }
  .gNavForm {
    width: 100%;
    max-width: 500px;
    position: absolute;
    top: 25px;
    right: calc((100% - 1020px) / 2);
    dl {
      overflow: hidden;
      border-radius: 50px;
    }
    dt {
      background: url(/images/icon_search.png) no-repeat 12px center, #eee;
      background-size: 21px 21px;
      float: left;
      width: calc(100% - 105px);
      input {
        width: 100%;
        padding: 12px 9px 12px 45px;
        &::placeholder {
          font-size: 1.4rem;
        }
      }
    }
    dd {
      font-size: 2rem;
      font-weight: bold;
      color: #fff;
      background: $font-color-link;
      float: right;
      width: 105px;
      padding: 0 0;
      position: relative;
      &::after {
        content: "→";
        font-size: 2.5rem;
        font-weight: bold;
        color: #fff;
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
      }
      button {
        width: 100%;
        text-align: left;
        padding: 9px 0 9px 20px;
        cursor: pointer;
        position: relative;
        z-index: 10;
      }
    }
  }
}
.ttl--site{
  @include ttl-base(1.4rem);
  margin: 4px 0 0;
  position: relative;
  a {
    color: $font-color-base;
    display: inline-block;
    position: relative;
    z-index: 100;
  }
  span {
    display: block;
    margin: 0 0 5px;
  }
  &__inner {
    max-width: $base-width + 170px;
    margin: 0 auto;
    padding: 24px 0 30px 170px;
    position: relative;
    &::before {
      content: "";
      width: 242px;
      height: 185px;
      background: url(/images/header_deco_ribbon_pc.png) no-repeat;
      display: block;
      position: absolute;
      top: -4px;
      left: 0;
      z-index: 10;
    }
  }
  &::before {
    content: "";
    width: 100%;
    height: 4px;
    position: absolute;
    top: 0;
    left: 0;
    background: repeat-x center center;
    background-image: url(/images/header_border.png);
  }
  &::after {
    content: "";
    width: 100%;
    height: 4px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: repeat-x center center;
    background-image: url(/images/header_border.png);
  }
}
.sp--iconNav {
  display: none;
}
@media screen and (max-width: 1100px){
  .header__top__btn {
    right: 0;
  }
}

@media screen and (max-width: $display-width-s){
  /* =================================
    header
  ================================= */
  .ttl--site{
    margin: 0;
    span {
      font-size: 1rem;
      display: block;
      margin: 0 0 3px 21px;
    }
    img {
      width: auto;
      height: 16px;
    }
    &__inner {
      padding: 7px 0;
      text-align: center;
      &::before {
        content: none;
        width: 90px;
        height: 75px;
        background-size: contain;
        top: -2px;
        left: -10px;
      }
    }
    &::before {
      height: 0;
    }
    &::after {
      height: 1px;
    }
  }
  .header {
    width: 100%;
    background: #fff;
    position: relative;
    margin: 50px 0 0;
    &Nav__cover {
      height: 0;
      width: 100%;
      background: rgba(243, 245, 244, 0.5);
      position: fixed;
      top: 49px;
      display: none;
      z-index: 100;
      &.show {
        display: block;
        animation: show .2s linear 0s;
      }
      &.hide {
        display: none;
        animation: hide .2s linear 0s;
      }
    }
  }
  .gNav {
    display: none;
    overflow-y: scroll;
    width: 100%;
    position: fixed;
    top: 49px;
    left: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    z-index: 200;
    &__list {
      display: block;
    }
    .gNav__list__item {
      font-size: 1.4rem;
      display: block;
      width: 100%;
      border-bottom: 1px dashed #eee;
      a {
        position: relative;
        border: none;
        text-align: left;
        padding: 20px 10px 20px 50px;
      }
    }
    .gNav__list__item:first-child a {
      border-left: none;
    }
    .gNavForm {
      font-size: 1.6rem;
      width: 100%;
      display: block;
      padding: 20px 10px 30px;
      position: relative;
      top: 0;
      right: 0;
      dt {
        background: url(/images/icon_search.png) no-repeat 15px center, #eee;
        background-size: 18px 18px;
        width: calc(100% - 80px);
        input::placeholder {
          font-size: 1.2rem;
        }
      }
      dd {
        font-size: 1.5rem;
        width: 80px;
        padding: 0 0;
        position: relative;
        &::after {
          content: "→";
          font-size: 1.5rem;
          right: 10px;
        }
        button {
          padding: 13px 0 13px 16px;
        }
        
      }
    }
  }

  /*バーガーボタン設定*/
  .burger {
    width: 30px;
    height: 20px;
    margin: auto;
    position: absolute;
    right: 12px;
    top: 20px;
    cursor: pointer;
    z-index: 205;
    transform: translateY(-50%);
    &::after {
      content: "メニュー";
      font-size: 1rem;
      font-weight: bold;
      line-height: 1;
      letter-spacing: -.1em;
      color: $font-color-link;
      width: 40px;
      position: absolute;
      bottom: -13px;
      left: -2px;
    }
  }
  .burger span {
    width: 25px;
    height: 3px;
    display: block;
    background: $font-color-link;
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    margin: 0 auto;
    transition: width 0.2s, right 0.2s, left 0.2s;
  }
  .burger .burger--top {
    transform: translateY(-10px);
  }
  .burger .burger--middle {
    transform: translateY(-1.5px);
    position: relative;
    background: none;
  }
  .burger .burger--middle:before {
    content: "";
    display: block;
    position: absolute;
    width: 25px;
    height: 3px;
    background: $font-color-link;
    transition: all 0.2s;
    transform: rotate(0deg);
  }
  .burger .burger--middle:after {
    content: "";
    display: block;
    position: absolute;
    width: 25px;
    height: 3px;
    background: $font-color-link;
    transition: all 0.2s;
    transform: rotate(0deg);
  }
  .burger .burger--bottom {
    transform: translateY(7px);
  }
  /*クリック後、バツボタンになる*/
  .burger.is-open .burger--top {
    left: 100%;
    width: 0px;
  }
  .burger.is-open .burger--middle:before {
    transform: rotate(135deg);
  }
  .burger.is-open .burger--middle:after {
    transform: rotate(45deg);
  }
  .burger.is-open .burger--bottom {
    right: 100%;
    width: 0px;
  }
  .sp--iconNav {
    display: block;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 200;
    background: #fff;
    border-bottom: 1px solid #e6ddbd;
    &__list {
      display: flex;
      width: calc(100% - 50px);
      > li {
        width: 25%;
        text-align: center;
        font-size: 1.1rem;
        > span {
          color: #2facde;
          display: block;
        }
        > a {
          color: #2facde;
          display: block;
        }
      }
      .icon {
        position: relative;
        padding: 22px 0 4px;
        &::before {
          font-size: 1.4rem;
          color: #2facde;
          position: absolute;
          top: 5px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }
  .fullMenu__area {
    width: 100%;
    height: 90vh;
    background: #fff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 500;
    transform: scale(1.1);
    transition-property: transform;
    transition-duration: 0.4s;
    -webkit-overflow-scrolling: touch;
    display: none;
    opacity: 0;
    .ttl {
      font-size: 1.6rem;
      font-weight: 600;
      color: #fff;
      text-align: center;
      padding: 12px 0 8px;
      background: linear-gradient(to right, #297dd2 0%, #3997e7 50%, #2989d8 100%);
      position: relative;
      &::before {
        content: "";
        width: 100%;
        height: 2px;
        position: absolute;
        top: 3px;
        left: 0;
        background: url(/images/border_section_ttl.png) repeat-x;
        display: block;
      }
    }
    .searchPrefectures__block {
      padding: 30px 3% 0;
      margin: 0 0 35px;
      height: calc(90vh - 135px);
      overflow-y: auto;
      .searchPrefectures__subTtl {
        margin: 0 0 10px;
      }
      > li {
        & + li {
          margin: 15px 0 0;
        }
      }
    }
    .searchPrefectures__block__list li {
      width: calc(33.333333% - 1px);
      font-size: 1.4rem;
      &:nth-child(4n + 1) {
        margin-left: 1px;
      }
      a {
        padding: 8px 0;
      }
    }
    &.is-open {
      transform: scale(1);
      opacity: 1;
    }
    .fullMenu__btn--close {
      font-size: 1.4rem;
      padding: 30px 0 0;
      position: absolute;
      bottom: 3%;
      left: 50%;
      transform: translateX(-50%);
      color: $font-color-link;
      &::before {
        font-size: 2.5rem;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}
.no-scroll {
  overflow: hidden;
}