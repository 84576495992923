@charset "utf-8";
/* =================================
  side nav
================================= */
.sideNav {
  /*---------------------------
    サイドナビ共通
  ---------------------------*/
  background: #b0d4ed;
  padding: 10px;
  &__ttl {
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 1;
    background: #e1f2f2;
    position: relative;
    padding: 2px 0;
    &::before {
      content: "";
      position: absolute;
      top: 100%;
      height: 0;
      width: 0;
      border: 4px solid transparent;
      border-top: 4px solid #006661;
      right: 0;
      border-left: 4px solid #006661;
    }
    &::after {
      content: "";
      position: absolute;
      top: 100%;
      height: 0;
      width: 0;
      border: 4px solid transparent;
      border-top: 4px solid #006661;
      left: 0;
      border-right: 4px solid #006661;
    }
    span {
      display:block;
      border-top: 1px solid #009e96;
      border-bottom: 1px solid #009e96;
      padding: 17px 20px;
    }
  }
  &__sec {
    & + .sideNav__sec {
      margin: 20px 0 0;
    }
    &__in {
      background: #fff;
      margin: 0 8px;
    }
  }
  /*---------------------------
    人気記事　アクセスランキング
  ---------------------------*/
  .sideNav__articleRanking {
    .sideNav__ttl span {
      position: relative;
      padding-left: 54px;
      &::before {
        content: "";
        width: 33px;
        height: 32px;
        background: url(/images/icon_sideNav_ranking.png) no-repeat center center;
        background-size: contain;
        position: absolute;
        left: 12px;
      }
    }
    .ranking__list {
      padding-bottom: 15px;
      li {
        position: relative;
        padding: 15px 10px;
        border-bottom: 1px dashed #6c9ac6;
        a {
          font-size: 1.6rem;
          line-height: 1.375;
          display: block;
          position: relative;
          margin-bottom: 10px;
        }
        &:nth-child(1) a {
          padding-left: 45px;
          &::before {
            content: "";
            width: 36px;
            height: 47px;
            background: url(/images/ranking_icon_ttl01.png) no-repeat center center;
            background-size: contain;
            display: inline-block;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
        &:nth-child(2) a {
          padding-left: 45px;
          &::before {
            content: "";
            width: 36px;
            height: 47px;
            background: url(/images/ranking_icon_ttl02.png) no-repeat center center;
            background-size: contain;
            display: inline-block;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
        &:nth-child(3) a {
          padding-left: 45px;
          &::before {
            content: "";
            width: 36px;
            height: 47px;
            background: url(/images/ranking_icon_ttl03.png) no-repeat center center;
            background-size: contain;
            display: inline-block;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
  /*------ 下層 ------*/
  .sideNav__articleRanking.under {
    .sideNav__ttl span {
      &::before {
        background: url(/images/icon_sideNav_pickUp.png) no-repeat center center;
        background-size: contain;
      }
    }
    .ranking__list {
      li {
        display: flex;
        align-items: flex-start;
        img {
          width: 60px;
          margin-right: 14px;
        }
        a {
          width: calc(100% - 60px);
          font-size: 1.4rem;
          line-height: 1.57142;
          margin-bottom: 0;
        }
        &:nth-child(1) {
          a {
            padding-left: 0;
            &::before {
              content: none;
            }
          }
          &::before {
            content: "";
            width: 28px;
            height: 28px;
            background: url(/images/ranking_iconSm_ttl01.png) no-repeat center center;
            background-size: contain;
            display: inline-block;
            position: absolute;
            top: 6px;
            left: 2px;
          }
        }
        &:nth-child(2) {
          a {
            padding-left: 0;
            &::before {
              content: none;
            }
          }
          &::before {
            content: "";
            width: 28px;
            height: 28px;
            background: url(/images/ranking_iconSm_ttl02.png) no-repeat center center;
            background-size: contain;
            display: inline-block;
            position: absolute;
            top: 6px;
            left: 2px;
          }
        }
        &:nth-child(3) {
          a {
            padding-left: 0;
            &::before {
              content: none;
            }
          }
          &::before {
            content: "";
            width: 28px;
            height: 28px;
            background: url(/images/ranking_iconSm_ttl03.png) no-repeat center center;
            background-size: contain;
            display: inline-block;
            position: absolute;
            top: 6px;
            left: 2px;
          }
        }
      }
    }
  }
  /*---------------------------
    人気サロン　ランキング
  ---------------------------*/
  .sideNav__salonRanking {
    .sideNav__ttl span {
      position: relative;
      padding: 17px 10px 17px 54px;
      font-size: 1.6rem;
      &::before {
        content: "";
        width: 33px;
        height: 32px;
        background: url(/images/icon_sideNav_ranking.png) no-repeat center center;
        background-size: contain;
        position: absolute;
        left: 12px;
      }
    }
    .ranking__list {
      padding-bottom: 15px;
      > li {
        position: relative;
        padding: 15px 10px;
        border-bottom: 1px dashed #6c9ac6;
        a {
          font-size: 1.8rem;
          font-weight: bold;
          display: block;
          position: relative;
          margin-bottom: 10px;
        }
        &:nth-child(1) a {
          padding: 1rem 12px 10px 45px;
          line-height: 1.25;
          &::before {
            content: "";
            width: 36px;
            height: 47px;
            background: url(/images/ranking_icon_ttl01.png) no-repeat center center;
            background-size: contain;
            display: inline-block;
            position: absolute;
            top: 0;
            left: 0;
          }
          &::after {
            content: "\f105";
            font-family: "FontAwesome";
            font-size: 1.5rem;
            font-weight: bold;
            text-align: center;
            line-height: 42px;
            text-decoration: none;
            color: #fff;
            width: 11px;
            height: 42px;
            background: #c4ac37;
            display: inline-block;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            border-radius: 0 3px 3px 0;
          }
        }
        &:nth-child(2) a {
          padding: 1rem 12px 10px 45px;
          line-height: 1.25;
          &::before {
            content: "";
            width: 36px;
            height: 47px;
            background: url(/images/ranking_icon_ttl02.png) no-repeat center center;
            background-size: contain;
            display: inline-block;
            position: absolute;
            top: 0;
            left: 0;
          }
          &::after {
            content: "\f105";
            font-family: "FontAwesome";
            font-size: 1.5rem;
            font-weight: bold;
            text-align: center;
            line-height: 42px;
            text-decoration: none;
            color: #fff;
            width: 11px;
            height: 42px;
            background: #a0a0a0;
            display: inline-block;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            border-radius: 0 3px 3px 0;
          }
        }
        &:nth-child(3) a {
          padding: 1rem 12px 10px 45px;
          line-height: 1.25;
          &::before {
            content: "";
            width: 36px;
            height: 47px;
            background: url(/images/ranking_icon_ttl03.png) no-repeat center center;
            background-size: contain;
            display: inline-block;
            position: absolute;
            top: 0;
            left: 0;
          }
          &::after {
            content: "\f105";
            font-family: "FontAwesome";
            font-size: 1.5rem;
            font-weight: bold;
            text-align: center;
            line-height: 42px;
            text-decoration: none;
            color: #fff;
            width: 11px;
            height: 42px;
            background: #b28850;
            display: inline-block;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            border-radius: 0 3px 3px 0;
          }
        }
        img {
          width: 100%;
          height: auto;
        }
      }
      .evaluation {
        display: flex;
        align-items: center;
        margin: 10px 0;
        &Ttl {
          font-size: 1.1rem;
          line-height: 1;
          margin-right: 6px;
        }
        &Text {
          font-size: 2rem;
          font-weight: bold;
          line-height: 1;
          color: #a80000;
          margin-left: 6px;
        }
        .star {
          display: flex;
          li {
            width: 19px;
            height: auto;
            line-height: 1;
          }
        }
      }
    }
  }
  /*---------------------------
    おすすめ記事　ピックアップ
  ---------------------------*/
  .sideNav__pickUp {
    .sideNav__ttl span {
      position: relative;
      padding-left: 54px;
      &::before {
        content: "";
        width: 33px;
        height: 32px;
        background: url(/images/icon_sideNav_pickUp.png) no-repeat center center;
        background-size: contain;
        position: absolute;
        left: 12px;
      }
    }
    .pickUp__list {
      padding-bottom: 15px;
      li {
        display: flex;
        align-items: flex-start;
        border-bottom: 1px dashed #bababa;
        padding: 15px 10px;
        img {
          width: 60px;
          height: auto;
          margin-right: 14px;
        }
        a {
          font-size: 1.4rem;
          line-height: 1.5714;
          width: calc(100% - 74px);
        }
      }
    }
  }
  /*---------------------------
    カテゴリー
  ---------------------------*/
  .sideNav__category {
    .category__list {
      padding-bottom: 18px;
      li {
        padding: 16px 14px;
        border-bottom: 1px dashed #bababa;
        a {
          font-size: 1.8rem;
          line-height: 1.25;
          display: block;
          padding-left: 27px;
          position: relative;
          &::before {
            content: "\f138";
            font-family: "FontAwesome";
            color: $font-color-link;
            font-size: 1.8rem;
            line-height: 1;
            text-decoration: none;
            display: inline-block;
            position: absolute;
            top: 2px;
            left: 0;
          }
        }
      }
    }
  }
  /*---------------------------
    エリア別　脱毛ガイド
  ---------------------------*/
  .area__list {
    padding-bottom: 18px;
    & > li {
      padding: 15px 12px;
      border-bottom: 1px dashed #bababa;
      ul {
        padding-left: 1em;
        a {
          font-size: 1.4rem;
          letter-spacing: -.07em;
          &:before {
            content: "・";
          }
        }
      }
    }
    > li {
      > a {
        font-size: 1.4rem;
        letter-spacing: -.07em;
        position: relative;
        padding-left: 18px;
        &:before {
          content: "\f138";
          font-family: "FontAwesome";
          color: #1f68b1;
          font-size: 1.6rem;
          line-height: 1;
          text-decoration: none;
          display: inline-block;
          position: absolute;
          top: 2px;
          left: 0;
        }
      }
    }
  }
  /*---------------------------
    エリア検索
  ---------------------------*/
  .searchPrefectures__block {
    width: auto;
    width: initial;
    padding: 20px 10px;
    &__list {
      overflow: hidden;
      li {
        width: 47px;
        &:nth-child(4n + 1) {
          margin-left: 0!important;
        }
        &:nth-child(5n + 1) {
          margin-left: 5px;
        }
      }
    }
  }
}

@media screen and (max-width: $display-width-s) {
  /* =================================
    side nav
  ================================= */
  .sideNav {
    /*---------------------------
      サイドナビ共通
    ---------------------------*/
    padding: 10px 8px 10px;
    &__ttl {
      font-size: 1.6rem;
      line-height: 1.2;
      padding: 1px 0;
      &::before {
        border: 2px solid transparent;
        border-top: 2px solid #006661;
        border-left: 2px solid #006661;
      }
      &::after {
        border: 2px solid transparent;
        border-top: 2px solid #006661;
        border-right: 2px solid #006661;
      }
      span {
        padding: 11px 14px;
      }
    }
    &__sec {
      & + .sideNav__sec {
        margin: 10px 0 0;
      }
      &__in {
        margin: 0 4px;
      }
    }
    /*---------------------------
      人気記事　アクセスランキング
    ---------------------------*/
    .sideNav__articleRanking {
      .ranking__list {
        padding-bottom: 15px;
        li {
          position: relative;
          padding: 12px;
          min-height: 90px;
          a {
            font-size: 1.4rem;
            line-height: 1.57142;
            padding: 1rem 0 10px;
            width: calc(100% - 90px);
          }
          &:nth-child(1) a {
            padding-left: 42px;
            &::before {
              width: 31px;
              height: 42px;
            }
          }
          &:nth-child(2) a {
            padding-left: 42px;
            &::before {
              width: 31px;
              height: 42px;
            }
          }
          &:nth-child(3) a {
            padding-left: 42px;
            &::before {
              width: 31px;
              height: 42px;
            }
          }
          img {
            width: auto;
            height: 70px;
            position: absolute;
            top: 10px;
            right: 10px;
          }
        }
      }
    }
    /*------ 下層 ------*/
    .sideNav__articleRanking.under {
      .ranking__list {
        li {
          min-height: 70px;
          img {
            height: auto;
            margin-right: 13px;
            position: static;
          }
          &:nth-child(1) {
            &::before {
              width: 25px;
              height: 25px;
              top: 5px;
              left: 5px;
            }
          }
          &:nth-child(2) {
            &::before {
              width: 25px;
              height: 25px;
              top: 5px;
              left: 5px;
            }
          }
          &:nth-child(3) {
            &::before {
              width: 25px;
              height: 25px;
              top: 5px;
              left: 5px;
            }
          }
        }
      }
    }
    /*---------------------------
      人気サロン　ランキング
    ---------------------------*/
    .sideNav__salonRanking {
      .ranking__list {
        padding-bottom: 10px;
        > li {
          padding: 10px;
          min-height: 90px;
          position: relative;
          a {
            font-size: 1.4rem;
            width: calc(100% - 113px);
          }
          &:nth-child(1) a {
            padding: 1rem 0 10px 42px;
            &::before {
              width: 31px;
              height: 42px;
            }
            &::after {
              content: none;
            }
          }
          &:nth-child(2) a {
            padding: 1rem 0 10px 42px;
            &::before {
              width: 31px;
              height: 42px;
            }
            &::after {
              content: none;
            }
          }
          &:nth-child(3) a {
            padding: 1rem 0 10px 42px;
            &::before {
              width: 31px;
              height: 42px;
            }
            &::after {
              content: none;
            }
          }
          > img {
            width: auto;
            height: 70px;
            position: absolute;
            top: 10px;
            right: 10px;
          }
        }
        .evaluation {
          width: calc(100% - 113px);
          &Ttl {
            font-size: 1rem;
            margin-right: 6px;
          }
          &Text {
            font-size: 1.4rem;
          }
          .star {
            li {
              width: 13px;
            }
          }
        }
      }
    }
    /*---------------------------
      おすすめ記事　ピックアップ
    ---------------------------*/
    .sideNav__pickUp {
      .pickUp__list {
        padding-bottom: 10px;
        li {
          padding: 12px;
          img {
            width: 60px;
          }
        }
      }
    }
    /*---------------------------
      カテゴリー
    ---------------------------*/
    .sideNav__category {
      .category__list {
        padding-bottom: 10px;
        li {
          padding: 12px;
          a {
            font-size: 1.4rem;
            padding-left: 0;
            &::before {
              left: initial;
              right: 0;
            }
          }
        }
      }
    }
    /*---------------------------
      エリア別　脱毛ガイド
    ---------------------------*/
    .area__list {
      padding-bottom: 10px;
      & > li {
        padding: 12px;
      }
    }
    /*---------------------------
      エリア検索
    ---------------------------*/
    .searchPrefectures__block {
      padding: 12px;
      &__list {
        li {
          width: calc(25% - 0.75px);
          margin: 1px 0 0 1px!important;
          &:nth-child(4n + 1) {
            margin-left: 0!important;
          }
          &:nth-child(5n + 1) {
            margin-left: 0;
          }
          &:nth-child(6n + 1) {
            margin-left: 0;
          }
        }
      }
    }
  }
}