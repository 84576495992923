@charset "utf-8";

//transition初期設定
@mixin animation-base($property:all, $duration:.2s, $timing:ease-out) {
  transition-property: $property;
  transition-duration: $duration;
  transition-timing-function: $timing;
}
.animation--fadedown {
    @include animation-base(all,.5s);
    transition-delay: .2s;
    opacity:0;
    transform:translateY(-50px);
    &.active {
        opacity:1;
        transform: translateY(0);
    }
}
@keyframes bar00 {
    0% {
        width: 0%;
    }
    100% {
        width: 5%;
    }
}
@keyframes bar01 {
    0% {
        width: 0%;
    }
    100% {
        width: 20%;
    }
}
@keyframes bar02 {
    0% {
        width: 0%;
    }
    100% {
        width: 30%;
    }
}
@keyframes bar03 {
    0% {
        width: 0%;
    }
    100% {
        width: 40%;
    }
}
@keyframes bar04 {
    0% {
        width: 0%;
    }
    100% {
        width: 50%;
    }
}
@keyframes bar05 {
    0% {
        width: 0%;
    }
    100% {
        width: 60%;
    }
}
@keyframes bar06 {
    0% {
        width: 0%;
    }
    100% {
        width: 70%;
    }
}
@keyframes bar07 {
    0% {
        width: 0%;
    }
    100% {
        width: 80%;
    }
}
@keyframes bar08 {
    0% {
        width: 0%;
    }
    100% {
        width: 90%;
    }
}
@keyframes bar09 {
    0% {
        width: 0%;
    }
    100% {
        width: 95%;
    }
}

@media screen and (min-width: 769px){
    /*----------------------------------
    スクロールで画像がふわっと出てくる
    ----------------------------------*/
    .animation--fadeup {
        @include animation-base(all,.7s);
        opacity:0;
        transform:translateY(70px);
        &.active {
            opacity:1;
            transform: translateY(0);
        }
    }
    .animation--fadedown {
        @include animation-base(all,.5s);
        transition-delay: .2s;
        opacity:0;
        transform:translateY(-50px);
        &.active {
            opacity:1;
            transform: translateY(0);
        }
    }
    /*----------------------------------
    ズームする
    ----------------------------------*/
    .animation--zoom{
    overflow: hidden;
    }
    .animation--zoom img {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transition: .3s ease-in-out;
        transition: .3s ease-in-out;
    }
}