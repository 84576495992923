@charset "utf-8";

/* =================================
  都道府県検索
================================= */
.searchPrefectures {
  background: #ebe7db;
  border-radius: 3px;
  padding: 6px;
  width: calc(100% - 458px);
  margin: 0 0 0 35px;
  &__ttl {
    font-size: 2.5rem;
    font-weight: 600;
    line-height: 1;
    text-align: center;
    padding: 14px 0 20px;
  }
  &__subTtl {
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1;
    margin: 0 0 3px;
  }
  &__item {
    padding: 20px;
    border: 1px solid #d7cbab;
    border-radius: 3px;
    background: #fff no-repeat center center;
    background-image: url(/images/prefectures_bg.png);
    max-width: 550px;
    display: flex;
    flex-wrap: wrap;
  }
  &__block {
    width: calc(50% - 10px);
    &__list {
      overflow: hidden;
      li {
        width: 44px;
        border: 1px solid #d7d7d7;
        box-sizing: border-box;
        font-size: 14px;
        letter-spacing: -1px;
        line-height: 22px;
        text-align: center;
        margin: 5px 0 0 5px;
        float: left;
        a {
          font-weight: 600;
          display: block;
          border: 1px solid #fff;
          text-decoration: none;
          background: #eee;
          background: linear-gradient(to bottom, #eee 0%,#fff 100%);
        }
        &:nth-child(5n + 1) {
          margin-left: 0;
        }
      }
    }
    > li + li {
      margin: 20px 0 0;
    }
    & + .searchPrefectures__block {
      margin: 0 0 0 20px;
    }
  }
}


/* =================================
  toppage
================================= */
.mv {
  height: 544px;
  background: url(/images/mv_bg.png) #f4f6f5;
  background-repeat: no-repeat;
  background-position: center bottom;
  padding: 10px 0 0;
  position: relative;
  z-index: 20;
  &__inner {
    max-width: $base-width;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }
  &Catch {
    width: 423px;
    min-height: 454px;
    background: url(/images/mv_catch_bg.png) no-repeat center center;
    position: relative;
    &__text {
      text-align: center;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      h1 {
        font-size: 3.4rem;
        font-weight: 600;
        line-height: 1.2352941;
        color: #f58a0c;
      }
      span {
        font-size: 1.4rem;
        font-weight: 600;
        line-height: 1.5714285;
        color: $font-color-base;
        display: block;
        margin: 10px 0 0;
      }
    }
    &__btn {
      width: 100%;
      position: absolute;
      bottom: 40px;
      margin: 0;
      &.btn--pink a {
        padding: 18px 0;
        margin: 0;
        display: block;
      }
    }
  }
}
.topFeature {
  background: url(/images/main_bg.png) repeat top left;
  padding: 80px 0 30px;
  position: relative;
  top: -55px;
  z-index: 10;
}
.topSecCont {
  background: #fff;
  padding: 20px 20px 30px;
}
.secCont  + .secCont {
  margin: 20px 0 0;
}
/*campaignList*/
.campaignList {
  display: flex;
  &__item {
    width: calc(20% - 16px);
    & + .campaignList__item {
      margin: 0 0 0 20px;
    }
    a {
      color: #313131;
      display: block;
      text-decoration: none;
      &:visited {
        .campaignList__ttl,
        .campaignList__summary {
          color: #96428d;
        }
      }
    }
    &:hover {
      .campaignList__ttl a {
        color: #313131!important;
      }
      .campaignList__summary {
        color: $accent-color;
        text-decoration: none;
      }
    }
  }
  &__ttl {
    font-size: 1.7rem;
    font-weight: 600;
    color: $font-color-base;
    padding: 8px 0 10px;
    margin: 4px 0 0;
    border-top: 1px solid #ded4a2;
    position: relative;
    &::before {
      content: "";
      width: 100%;
      height: 2px;
      position: absolute;
      top: -4px;
      left: 0;
      background: #ded4a2;
    }
  }
  &__summary {
    font-size: 1.4rem;
    line-height: 1.5714285;
    color: $font-color-link;
    text-decoration: underline;
    margin: 5px 0 0;
  }
}
/*secCheckpoint*/
.secCheckpoint {
  .topSecCont {
    padding: 25px;
    margin-bottom: 30px;
    background: url(/images/bg_checkpoint.png) repeat top left;
    border-radius: 15px;
    .topSecWrap__inner {
      background: #fff;
      border-radius: 15px;
      padding: 50px 34px 34px;
      position: relative;
      &::before {
        content: "";
        width: 100%;
        height: 36px;
        position: absolute;
        top: -13px;
        left: 0;
        background: url(/images/point_deco_ring.png) repeat-x top left;
      }
    }
  }
  .checkpoint {
    &__head {
      position: relative;
      &__ttl {
        display: inline-block;
        vertical-align: middle;
        font-size: 2.6rem;
        margin-right: 60px;
        padding: 17px 0 17px 30px;
        position: absolute;
        left: -44px;
        color: #fff;
        background: #9b67c7;
        &::before {
          content: "";
          width: 0;
          height: 0;
          border-width: 0 10px 10px 0;
          border-style: solid;
          border-color: transparent;
          border-right-color: #6a3e8f;
          box-sizing: border-box;
          display: inline-block;
          position: absolute;
          top: 100%;
          left: 0;
        }
        &::after {
          content: "";
          width: 48px;
          height: 100%;
          background-image: url(/images/point_bg_ttl.png);
          display: inline-block;
          position: absolute;
          top: 0;
          right: -48px;
        }
      }
      &__desc {
        font-size: 1.6rem;
        font-weight: 600;
        padding: 6px 0 0 412px;
      }
    }
    &__list {
      background: url(/images/point_img01.png) no-repeat center center;
      display: flex;
      justify-content: space-between;
      margin: 35px 0 0;
      position: relative;
      li {
        background: url(/images/bg_checkpoint_line1.png) no-repeat center center;
        background-size: 100% auto;
        &.line2 {
          background: url(/images/bg_checkpoint_line2.png) no-repeat center center;
        }
        a {
          font-size: 1.8rem;
          font-weight: bold;
          line-height: 1;
          color: $font-color-base;
          text-align: center;
          display: block;
          text-decoration: none;
          padding: 13px 48px 9px;
          span {
            display: block;
            font-size: 1.4rem;
            margin: 5px 0 0;
          }
          &:hover {
            color: $accent-color;
          }
        }
        & + li {
          margin: 10px 0 0;
        }
      }
      &::after {
        content: "";
        width: 306px;
        height: 152px;
        background: url(/images/point_deco_tag.png) no-repeat center center;
        background-size: contain;
        position: absolute;
        bottom: 0;
        right: -59px;
      }
    }
  }
}
/*secReport*/
.secReport {
  background: #fff;
}
.report__list {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 20px 30px;
  margin-bottom: 30px;
  &__item {
    width: calc(50% - 10px);
    &:nth-child(2n) {
      margin-left: 20px;
    }
    &:hover {
      background: #fff8fb;
      .reportTtl {
        background: #fcdfec;
        &::after {
          color: #cde4fb;
        }
      }
    }
    &:nth-child(n + 3) {
      margin-top: 20px;
    }
  }
  .reportTtl {
    font-size: 2rem;
    font-weight: bold;
    background: #fcfdd2;
    position: relative;
    padding: 15px 14px;
    &::before {
      content: "\f138";
      font-family: "FontAwesome";
      font-size: 1.6rem;
      color: $font-color-link;
      margin-right: 14px;
    }
  }
  .summary {
    display: flex;
    align-items: flex-start;
    padding: 16px;
    img {
      width: 138px;
      height: auto;
      margin-right: 13px;
    }
    p {
      font-size: 1.3rem;
      line-height: 1.6923;
    }
  }
}
/*secBasic*/
.basicBox {
  background: #fff;
  padding: 30px 20px;
  display: flex;
  &__ttl {
    font-size: 2.8rem;
    font-weight: bold;
    line-height: 1;
    margin-bottom: 13px;
    &::before {
      content: "";
      width: 30px;
      height: 25px;
      background: url(/images/icon_search02.png) no-repeat center center;
      background-size: contain;
      display: inline-block;
      vertical-align: baseline;
      margin-right: 9px;
    }
  }
  &__item {
    width: calc(50% - 20px);
    &:nth-child(2n) {
      margin-left: 40px;
    }
  }
}
.basicBox__btn {
  margin-top: 20px;
  a {
    font-size: 2.2rem;
    font-weight: bold;
    color: #fff;
    padding: 16px 24px;
    text-decoration: none;
    border-radius: 5px;
    border-top: 2px solid #c4e2e0;
    border-left: 2px solid #87c5c2;
    border-right: 1px solid #2d95b6;
    border-bottom: 2px solid #156b98;
    background: #42afaa;
    background: -webkit-linear-gradient(top, #42afaa 0%, #41bab4 1%, #33a59f 100%);
    background: -webkit-gradient(linear, left top, left bottom, from(#42afaa), color-stop(1%, #41bab4), to(#33a59f));
    background: linear-gradient(to bottom, #42afaa 0%, #41bab4 1%, #33a59f 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#42afaa', endColorstr='#33a59f',GradientType=0 );
    box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.3),inset 0px 5px 5px 0px rgba(63, 167, 162, 0.67);
    display: block;
    width: 100%;
    &::before {
      content: "";
      width: 38px;
      height: 28px;
      background: url(/images/icon_arrow_r.png) no-repeat center center;
      background-size: contain;
      display: inline-block;
      vertical-align: middle;
      margin-right: 18px;
    }
    &:hover {
      background: #33a59f;
      background: -webkit-linear-gradient(top, #33a59f 0%, #41bab4 99%, #42afaa 100%);
      background: -webkit-gradient(linear, left top, left bottom, from(#33a59f), color-stop(99%, #41bab4), to(#42afaa));
      background: linear-gradient(to bottom, #33a59f 0%, #41bab4 99%, #42afaa 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#33a59f', endColorstr='#42afaa',GradientType=0 );
      color: #fff;
    }
  }
}

/* =================================
  記事一覧
================================= */
.articleListWrap {
  .list--arrow {
    &__item {
      .summary {
        p {
          font-size: 1.5rem;
          line-height: 1.333333;
        }
      }
    }
  }
}
/* =================================
  記事詳細
================================= */
.article__detail {
  h1 {
    font-size: 3.6rem;
    font-weight: bold;
    line-height: 1.22222;
    padding: 34px 14px;
    margin-bottom: 50px;
    position: relative;
    &::before {
      content: "";
      width: 100%;
      height: 13px;
      background: url(/images/ttl_deco_top.png) no-repeat left center;
      background-size: cover;
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
    }
    &::after {
      content: "";
      width: 100%;
      height: 13px;
      background: url(/images/ttl_deco_bottom.png) no-repeat right center;
      background-size: cover;
      display: inline-block;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
  h2 {
    @include ttl-base(2.6rem,1.2);
    padding: 22px 20px 18px 20px;
    margin: 50px 0 30px;
    background: linear-gradient(to right, #297dd2 0%,#3997e7 50%,#2989d8 100%);
    position: relative;
    &::before {
      content: "";
      width: 100%;
      height: 3px;
      position: absolute;
      top: 4px;
      left: 0;
      background: url(/images/border_section_ttl.png) repeat-x;
      display: block;
    }
  }
  h3 {
    @include ttl-base(2.5rem,1.2,600,$font-color-base);
    border-top: 1px solid #009e96;
    background: url(/images/bg_h3.png) repeat left top;
    padding: 12px 23px;
    margin: 50px 0 30px;
  }
  h4 {
    @include ttl-base(2.4rem,1.2,600,$font-color-link);
    background: url(/images/icon_circle.png) no-repeat left 1px;
    background-size: 22px 22px;
    padding: 0 0 0 32px;
    margin: 50px 0 30px;
  }
  h5 {
    @include ttl-base(2rem,1.2,600,$font-color-base);
    margin: 30px 0 0px;
    background: linear-gradient(transparent 60%, #c4e7ff 60%);
    display: inline-block;
  }
  p {
    margin: 25px 0 0;
  }
  em {
    font-style: normal;
    font-weight: bold;
    color: #019992;
  }
  img {
    display: block;
    margin: 30px auto 20px;
  }
  > ol {
    background: #fffef2;
    border: 1px solid #ffeb8f;
    padding: 20px;
    margin: 22px 0 50px;
    li {
      font-size: 1.6rem;
      counter-increment: pointCount;
      padding-left: 30px;
      position: relative;
      &::before {
        content: counter(pointCount);
        font-size: 1.4rem;
        line-height: 1;
        color: #fff;
        padding: 3px 6px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        vertical-align: middle;
        background: #5fa2d0;
        border-radius: 50%;
        position: absolute;
        top: 2px;
        left: 0;
      }
      & + li {
        margin: 10px 0 0;
      }
    }
  }
  > ul:not([class]) {
    background: #fffef2;
    border: 1px solid #ffeb8f;
    padding: 20px;
    margin: 22px 0 50px;
    li {
      font-size: 1.6rem;
      padding-left: 20px;
      position: relative;
      &::before {
        content: "\f058";
        font-family: "FontAwesome";
        font-size: 1.6rem;
        color: #2f76bd;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        vertical-align: middle;
        position: absolute;
        top: 2px;
        left: 0;
      }
      & + li {
        margin: 10px 0 0;
      }
    }
  }
  table {
    margin: 60px 0 40px;
    border: 1px solid #b5b5b5;
    caption {
      font-size: 1.8rem;
      font-weight: bold;
      text-align: left;
      margin-bottom: 12px;
    }
    th {
      font-size: 1.6rem;
      line-height: 1.25;
      color: #fff;
      background: url(/images/bg_table.png) repeat left top;
      border: 1px solid #b5b5b5;
      padding: 9px 12px;
    }
    td {
      font-size: 1.6rem;
      line-height: 1.25;
      border: 1px dotted #949494;
      padding: 9px 12px;
    }
  }
  .searchPrefectures {
    width: 100%;
    margin: 50px 0 0;
    position: relative;
    &::before {
      content: "";
      width: 100px;
      height: 97px;
      background: url(/images/deco_mapL.png) no-repeat center center;
      background-size: contain;
      display: inline-block;
      position: absolute;
      top: -5px;
      left: -5px;
    }
    &::after {
      content: "";
      width: 100px;
      height: 97px;
      background: url(/images/deco_mapR.png) no-repeat center center;
      background-size: contain;
      display: inline-block;
      position: absolute;
      bottom: -5px;
      right: -5px;
    }
    &__ttl {
      margin: 0;
    }
    &__subTtl {
      margin: 0 0 6px;
    }
    &__item {
      max-width: 100%;
      max-width: initial;
    }
    &__block {
      &__list {
        li {
          &:nth-child(5n + 1) {
            margin-left: 5px!important;
          }
          &:nth-child(6n + 1) {
            margin-left: 0!important;
          }
        }
      }
    }
    .searchForm {
      width: 100%;
      border-top: 1px dashed #a9aa68;
      padding-top: 13px;
      margin-top: 25px;
      &__ttl {
        font-size: 1.7rem;
        line-height: 1;
        margin: 0 0 12px;
        &::before {
          content: "";
          width: 19px;
          height: 19px;
          background: url(/images/icon_search.png) no-repeat center center;
          background-size: contain;
          display: inline-block;
          vertical-align: middle;
          margin-right: 9px;
        }
      }
      dl {
        display: flex;
        input {
          width: 425px;
          border: 1px solid #d3d3d3;
          padding: 5px;
        }
        button {
          font-size: 1.7rem;
          line-height: 1;
          color: #fff;
          background: #1f68b1;
          border-radius: 3px;
          height: 100%;
          margin-left: 8px;
          padding: 0 25px;
          cursor: pointer;
        }
      }
    }
  }
}
.asideCont {
  margin: 28px 0 60px;
  .asideCont__ttl {
    font-size: 2.2rem;
    font-weight: bold;
    line-height: 1;
    padding: 0 0 16px 2px;
    margin: 0;
    border-top: none;
    background: none;
    position: relative;
  }
  &.recommend {
    .asideCont__ttl {
      &::before {
        content: "";
        width: 38px;
        height: 28px;
        background: url(/images/icon_ttl_recommend.png) no-repeat left center;
        background-size: contain;
        display: inline-block;
        vertical-align: middle;
        margin-right: 8px;
      }
    }
  }
  &.related {
    .asideCont__ttl {
      &::before {
        content: "";
        width: 38px;
        height: 28px;
        background: url(/images/icon_ttl_related.png) no-repeat left center;
        background-size: contain;
        display: inline-block;
        vertical-align: middle;
        margin-right: 8px;
      }
    }
  }
  &.new {
    .asideCont__ttl {
      &::before {
        content: "";
        width: 38px;
        height: 28px;
        background: url(/images/icon_ttl_new.png) no-repeat left center;
        background-size: contain;
        display: inline-block;
        vertical-align: middle;
        margin-right: 8px;
      }
    }
  }
}
/*キャンペーン情報*/
.tmBox {
  border: 6px solid #e1f2f2;
  padding: 15px 20px;
  margin: 40px 0;
  border-radius: 10px;
  display: flex;
  .thumb {
    width: 180px;
    margin: 0 30px 0 0;
    img {
      margin: 0!important;
    }
  }
  .tmSummary {
    width: calc(100% - 210px);
    .tm__ttl {
      border-bottom: 4px solid #eee;
      a {
        font-size: 1.8rem;
        line-height: 32px;
        text-decoration: none;
      }
    }
    .tm__text {
      font-size: 1.4rem;
      margin: 15px 0 0;
    }
    .tm__campaign {
      border: 1px solid #ef5697;
      position: relative;
      span {
        font-size: 1.1rem;
        color: #fff;
        padding: 5px;
        background: #ef5697;
        position: absolute;
        top: 0;
        left: 0;
      }
      p {
        font-weight: bold;
        line-height: 1.5;
        padding: 10px;
      }
    }
  }
}
/*よく読まれています*/
.relatedSingle {
  background: #fefbe0;
  padding: 25px 25px 15px;
  position: relative;
  border-radius: 2px;
  margin: 30px 0;
  border: 1px solid #eee;
  box-shadow: 1px 1px 5px #555;
  line-height: 1.5;
  position: relative;
  .related__ttl {
    font-size: 1.6rem;
    color: #fff;
    display: inline-block;
    position: absolute;
    top: -20px;
    left: 25px;
    padding: 0.2em 1em;
    background: #66B4D5;
    border-radius: 10px;
    &::after {
      content: "";
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      left: 20px;
      bottom: -10px;
      border: 5px solid transparent;
      border-top: 5px solid #66B4D5;
    }
  }
  &__in {
    display: flex;
    .thumb {
      width: 110px;
      margin: 0 18px 0 0;
      img {
        margin: 0;
      }
    }
    time {
      font-size: 1.5rem;
      display: block;
    }
  }
}
/* =================================
  サロン カード
================================= */
.salonCard {
  &__item {
    background: #e1f2f2;
    border-radius: 3px;
    padding: 5px;
    & + .salonCard__item {
      margin: 50px 0 0;
    }
    &__inner {
      background: #fff;
      border-radius: 3px;
      border: 1px solid #c1dfde;
      padding: 20px 20px 40px;
    }
  }
  &__ttl {
    font-size: 2.6rem!important;
    font-weight: 600!important;
    padding: 13px 0 13px 95px!important;
    position: relative!important;
    background: none!important;
    margin: 0!important;
    a {
      text-decoration: none;
    }
    span {
      font-size: 1.8rem;
      display: block;
      margin: 0 0 10px;
    }
    &::before {
      content: "";
      width: 73px!important;
      height: 73px!important;
      background: url(/images/icon_salon_list.png) no-repeat center center!important;
      background-size: contain!important;
      display: inline-block!important;
      position: absolute!important;
      left: 8px!important;
      top: -11px!important;
    }
  }
  &__left {
    width: 230px;
    margin: 0 20px 0 0;
    img {
      margin: 0;
    }
  }
  &__right {
    width: calc(100% - 250px);
    .rating {
      margin: 0 0 20px;
      border: 2px solid #e1f2f2;
      th{
        font-size: 2rem;
        color: $font-color-base;
        font-weight: bold;
        line-height: 1;
        padding: 10px 0;
        background: #e1f2f2;
        text-align: center;
      }
      td {
        border: 2px solid #e1f2f2;
        padding: 12px 15px;
      }
      .point {
        display: flex;
        .starArea {
          line-height: 1;
          li {
            display: inline;
            img {
              width: 22px;
              margin: 0;
              display: inline-block;
            }
          }
        }
        .pointText {
          font-size: 2.3rem;
          font-weight: bold;
          line-height: 1;
          color: #a80000;
          margin-left: 5px;
        }
      }
      .ranking {
        font-size: 1.4rem;
        font-weight: bold;
        margin-top: 10px;
        display: inline-block;
        &::before {
          content: "";
          width: 18px;
          height: 14px;
          background: url(/images/icon_review_subttl.png) no-repeat center center;
          background-size: contain;
          display: inline-block;
          margin-right: 8px;
        }
      }
      .buzz {
        text-align: center;
        a {
          font-size: 1.9rem;
          font-weight: 600;
          padding: 0 0 0 40px;
          background: url(/images/icon_reviews.png) no-repeat left center;
          background-size: contain;
          display: inline-block;
          line-height: 1;
        }
      }
    }
    .graph {
      border: 2px solid #e1f2f2;
      margin: -22px 0 20px;
      padding: 25px 5px 23px;
      table {
        width: auto;
        width: initial;
        margin: 0 auto;
        border: none;
        th,td {
          border: none;
          padding: 0 0 10px;
        }
        .graphNum {
          font-size: 1.8rem;
          font-weight: bold;
          &::before {
            content: "★";
            font-size: 1.6rem;
            color: #f3ca10;
            display: inline-block;
            vertical-align: middle;
            margin-right: 5px;
          }
        }
        .mater {
          width: 215px;
          height: 29px;
          padding: 0 12px 10px 15px;
          &__cover {
            width: 100%;
            height: 100%;
            background: #f2f2f2;
            position: relative;
          }
          &__bar {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            background: #00d8ce;
            background: -moz-linear-gradient(bottom, #00928b 0%, #00d8ce 100%);
            background: -webkit-linear-gradient(bottom, #00928b 0%, #00d8ce 100%);
            background: linear-gradient(to top, #00928b 0%, #00d8ce 100%);
            animation-fill-mode: forwards;
            animation-duration: 1s;
            animation-delay: .3s;
            animation-timing-function: ease-out;
            animation-iteration-count: 1;
            &.percent09 {
              animation-name: bar09;
            }
            &.percent08 {
              animation-name: bar08;
            }
            &.percent07 {
              animation-name: bar07;
            }
            &.percent06 {
              animation-name: bar06;
            }
            &.percent05 {
              animation-name: bar05;
            }
            &.percent04 {
              animation-name: bar04;
            }
            &.percent03 {
              animation-name: bar03;
            }
            &.percent02 {
              animation-name: bar02;
            }
            &.percent01 {
              animation-name: bar01;
            }
            &.percent00 {
              animation-name: bar00;
            }
          }
        }
        .ratingNum {
          font-size: 1.8rem;
          font-weight: bold;
        }
      }
    }
  }
  .info {
    background: #fafae6;
    padding: 15px 12px;
    .store {
      font-size: 1.6rem;
      text-align: center;
      margin: 0;
      line-height: 1;
      position: relative;
      > span {
        color: #2a7fd4;
        margin-left: 10px;
      }
      .num {
        font-size: 2.4rem;
        font-weight: bold;
      }
      &::before {
        content: "\f015";
        font-family: "FontAwesome";
        font-size: 2rem;
        color: #2a7fd4;
        margin-right: 5px;
      }
    }
    .fix {
      padding-top: 10px;
      margin-top: 12px;
      border-top: 1px dashed #a9aa68;
      li {
        font-size: 1.4rem;
        line-height: 1;
        color: #fff;
        text-align: center;
        padding: 8px 0;
        background: #b5b5b5;
        border-radius: 3px;
        position: relative;
        &::after {
          content: "";
          border-style: solid;
          border-width: 11px 11px 0 0;
          border-color: #d2d2d2 transparent transparent transparent;
          position: absolute;
          bottom: 0;
          right: 0;
        }
        & + li {
          margin-top: 5px;
        }
        &.applicable {
          background: #f58a0c;
          &::after {
            border-color: #fcbc72 transparent transparent transparent;
          }
        }
      }
    }
  }
  .feature {
    display: flex;
    flex-wrap: wrap;
    li {
      width: calc(16.666666% - 10.833333px);
      height: 60px;
      font-size: 1.2rem;
      line-height: 1.16666;
      font-weight: bold;
      color: #fff;
      text-align: center;
      background: #b5b5b5;
      border-radius: 5px;
      margin: 0 0 13px 13px;
      position: relative;
      &::after {
        content: "";
        border-style: solid;
        border-width: 11px 11px 0 0;
        border-color: #d2d2d2 transparent transparent transparent;
        position: absolute;
        bottom: 0;
        right: 0;
      }
      &.applicable {
        background: #2a7fd4;
        &::after {
          border-color: #53a5f6 transparent transparent transparent;
        }
      }
      > span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        display: block;
        width: 100%;
        span {
          display: block;
        }
      }
      &:nth-child(6n + 1) {
        margin-left: 0;
      }
    }
  }
  .cost {
    margin: 17px 0 0;
    border: 1px solid #d7cdb4;
    font-size: 1.6rem;
    line-height: 1;
    th {
      color: $font-color-base;
      background: #f8f4ea;
      text-align: center;
      border: 1px solid #d7cdb4;
      padding: 10px 0;
    }
    td {
      text-align: center;
      border: 1px solid #d7cdb4;
      padding: 10px 0;
      em {
        font-size: 2rem;
        font-weight: bold;
        font-style: normal;
        color: #d31a1a;
      }
    }
  }
  .campaign {
    text-align: center;
    border: 1px solid #ef5697;
    margin: 20px 0 0;
    &Ttl {
      font-size: 1.6rem;
      color: #fff;
      background: #ef5697;
      position: relative;
      margin: 0;
      &::before {
        content: "";
        width: 28px;
        height: 27px;
        background: url(/images/deco_campaignL.png) no-repeat center center;
        background-size: contain;
        display: inline-block;
        position: absolute;
        top: -1px;
        left: -1px;
        z-index: 10;
      }
      &::after {
        content: "";
        width: 28px;
        height: 27px;
        background: url(/images/deco_campaignR.png) no-repeat center center;
        background-size: contain;
        display: inline-block;
        position: absolute;
        bottom: 0px;
        right: -1px;
        z-index: 10;
      }
      span {
        position: relative;
        padding: 10px 5px;
        display: block;
        &::before {
          content: "";
          width: 100%;
          height: 1px;
          background: url(/images/deco_campaignBoder.png) no-repeat center center;
          background-size: 100% auto;
          display: inline-block;
          position: absolute;
          top: 2px;
          left: 50%;
          transform: translateX(-50%);
          z-index: 5;
        }
        &::after {
          content: "";
          width: 100%;
          height: 1px;
          background: url(/images/deco_campaignBoder.png) no-repeat center center;
          background-size: 100% auto;
          display: inline-block;
          position: absolute;
          bottom: 2px;
          left: 50%;
          transform: translateX(-50%);
          z-index: 5;
        }
      }
    }
    &Text {
      font-size: 1.5rem;
      font-weight: bold;
      line-height: 1.5;
      margin: 0;
      padding: 10px 5px;
    }
  }
  &__summary {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 20px 0 0;
  }
  &__link {
    text-align: center;
    margin: 25px 0 0;
    &__btn {
      font-size: 2.8rem;
      font-weight: bold;
      line-height: 1;
      color: #fff;
      text-decoration: none;
      padding: 18px 70px;
      margin: 0 0 20px;
      display: inline-block;
      border-radius: 5px;
      background: rgb(48,175,65);
      background: -moz-linear-gradient(top, rgba(48,175,65,1) 0%, rgba(48,202,48,1) 1%, rgba(49,179,49,1) 100%);
      background: -webkit-linear-gradient(top, rgba(48,175,65,1) 0%,rgba(48,202,48,1) 1%,rgba(49,179,49,1) 100%);
      background: linear-gradient(to bottom, rgba(48,175,65,1) 0%,rgba(48,202,48,1) 1%,rgba(49,179,49,1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#30af41', endColorstr='#31b331',GradientType=0 );
      box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.3),inset 0px 5px 5px 0px rgb(0, 181, 52);
      border-top: 2px solid #bfe6cf;
      border-left: 2px solid #7dc992;
      border-bottom: 1px solid #017623;
      border-right: 2px solid #009843;
      &:hover {
        background: rgb(48,175,65);
        background: -moz-linear-gradient(bottom, rgba(48,175,65,1) 0%, rgba(48,202,48,1) 1%, rgba(49,179,49,1) 100%);
        background: -webkit-linear-gradient(bottom, rgba(48,175,65,1) 0%,rgba(48,202,48,1) 1%,rgba(49,179,49,1) 100%);
        background: linear-gradient(to top, rgba(48,175,65,1) 0%,rgba(48,202,48,1) 1%,rgba(49,179,49,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#31b331', endColorstr='#30af4',GradientType=0 );
      }
      &:visited {
        color: #fff;
      }
    }
    p {
      line-height: 1;
      margin: 0;
    }
  }
}
/*------ ランキング内　サロンカード ------*/
.secRanking,.salonRanking {
  .salonCard {
    &__item {
      &:nth-child(1) .salonCard__ttl::before {
        background: url(/images/ranking_icon_ttl01.png) no-repeat left center!important;
        background-size: contain!important;
      }
      &:nth-child(2) .salonCard__ttl::before {
        background: url(/images/ranking_icon_ttl02.png) no-repeat left center!important;
        background-size: contain!important;
      }
      &:nth-child(3) .salonCard__ttl::before {
        background: url(/images/ranking_icon_ttl03.png) no-repeat left center!important;
        background-size: contain!important;
      }
    }
  }
}
#top {
  .secRanking {
    .salonCard {
      &__left {
        width: 320px;
        margin: 0 25px 0 0;
        img {
          width: 100%;
        }
      }
      &__right {
        width: calc(100% - 345px);
        .rating {
          .point {
            justify-content: center;
            &Text {
              font-size: 2.4rem;
            }
          }
        }
        .buzz {
          a {
            font-size: 2.4rem;
          }
        }
      }
    }
  }
}
/* =================================
  サロン ランキング
================================= */
.salonRanking {
  .rankingTtl {
    margin-bottom: 56px;
    img {
      width: 100%;
    }
  }
}
/* =================================
  サロン レポート
================================= */
.report__head {
  padding: 20px;
  background: url(/images/bg_articleRepo.png) repeat left top;
  position: relative;
  &::before {
    content: "";
    width: 100%;
    height: 13px;
    background: url(/images/ttl_deco_top.png) no-repeat left center;
    background-size: cover;
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
  }
  &::after {
    content: "";
    width: 100%;
    height: 13px;
    background: url(/images/ttl_deco_bottom.png) no-repeat right center;
    background-size: cover;
    display: inline-block;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .report__ttl {
    font-size: 3.2rem;
    line-height: 1.25;
    padding-left: 200px;
    margin: 0 0 15px;
    position: relative;
    &::before {
      content: "";
      width: 195px;
      height: 86px;
      background: url(/images/report_icon_pagettl.png) no-repeat center center;
      background-size: contain;
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .report__thumb {
    margin-bottom: 20px;
    border: 10px solid #fff;
    box-sizing: border-box;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
    img {
      width: 100%;
      height: auto;
    }
  }
  p {
    font-weight: bold;
  }
}
.report__info {
  margin: 15px 0 0;
  .reporterTtl {
    margin: 0 0 10px;
  }
  .reporter__2col {
    display: flex;
    .reporter__thumb {
      width: 100px;
      border-radius: 50%;
      margin-right: 20px;
    }
    .reporter__profile {
      width: calc(100% - 120px);
      .name {
        font-size: 2rem;
        font-weight: bold;
      }
      .text {
        font-size: 1.4rem;
        line-height: 1.7142;
      }
    }
  }
}
.menu {
  margin: 40px 0 0;
  &Ttl {
    position: relative;
    top: 0;
    margin-bottom: 0;
    padding-left: 12px;
    z-index: 99;
  }
  &Wrap {
    padding: 2px;
    border: 1px solid #eae1b4;
    box-shadow: 0 0 0 1px #f4f0d9 inset;
    position: relative;
    &::before {
      content: "";
      display: inline-block;
      position: absolute;
      top: -7px;
      left: -7px;
      width: 20px;
      height: 20px;
      background: no-repeat center center;
      background-image: url(/images/report_menu_deco.png);
      background-size: cover;
      z-index: 999;
      transform: rotate(90deg);
    }
    &::after {
      content: "";
      display: inline-block;
      position: absolute;
      top: -7px;
      right: -8px;
      width: 20px;
      height: 20px;
      background: no-repeat center center;
      background-image: url(/images/report_menu_deco.png);
      background-size: cover;
      z-index: 999;
      transform: rotate(180deg);
    }
  }
  &__list {
    margin-bottom: 0;
    padding: 15px;
    border: 1px solid #f7f4e3;
    background: #fff;
    display: flex;
    position: relative;
    &::before {
      content: "";
      display: inline-block;
      position: absolute;
      bottom: -7px;
      left: -10px;
      width: 20px;
      height: 20px;
      background: no-repeat center center;
      background-image: url(/images/report_menu_deco.png);
      background-size: cover;
      z-index: 999;
    }
    &::after {
      content: "";
      display: inline-block;
      position: absolute;
      bottom: -11px;
      right: -10px;
      width: 20px;
      height: 20px;
      background: no-repeat center center;
      background-image: url(/images/report_menu_deco.png);
      background-size: cover;
      z-index: 999;
      transform: rotate(-90deg);
    }
  }
  &__item {
    position: relative;
    &Ttl {
      font-size: 2rem;
      font-weight: bold;
      font-style: italic;
      line-height: 1;
      color: #776108;
      font-family: "Hiragino Mincho ProN", HG明朝E, "ＭＳ Ｐ明朝", serif;
      margin-bottom: 14px;
    }
    &Text {
      font-size: 2rem;
      line-height: 1.4;
    }
    &.course {
      width: 30%;
    }
    &.set {
      width: 35%;
    }
    &.price {
      width: 35%;
      .menu__itemText {
        font-size: 3rem;
        font-weight: bold;
        color: #a98e24;
      }
    }
    & + .menu__item {
      border-left: 2px solid #ded5a3;
      padding-left: 40px;
      margin-left: 24px;
      &::before {
        content: "";
        border-style: solid;
        border-width: 7.5px 0 7.5px 10px;
        border-color: transparent transparent transparent #ded5a3;
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }
      &::after {
        content: "";
        border-style: solid;
        border-width: 7.5px 0 7.5px 10px;
        border-color: transparent transparent transparent #fff;
        display: inline-block;
        position: absolute;
        top: 50%;
        left: -4px;
        transform: translateY(-50%);
      }
    }
  }
}
.repo__staff {
  padding: 8px;
  padding-right: 60px;
  background: url(/images/icon_medical_woman01.png) no-repeat right center;
  span {
    display: block;
    background: #e9e2c4;
    border-radius: 10px;
    padding: 8px 15px;
    position: relative;
    &::after {
      content: "";
      border-style: solid;
      border-width: 8px 0 0 17px;
      border-color: transparent transparent transparent #e9e2c4;
      display: inline-block;
      position: absolute;
      right: -10px;
      bottom: 40%;
    }
  }
}
.repo__self {
  padding: 8px;
  padding-left: 60px;
  background: url(/images/woman_question-1.png) no-repeat 0 center;
  span {
    display: block;
    background: #f9f5eA;
    border-radius: 10px;
    padding: 8px 15px;
    position: relative;
    &::after {
      content: "";
      border-style: solid;
      border-width: 8px 17px 0 0;
      border-color: transparent #f9f5eA transparent transparent;
      display: inline-block;
      position: absolute;
      left: -12px;
      bottom: 40%;
    }
  }
}
/* =================================
  contact
================================= */
.contactForm {
  th {
    vertical-align: top;
    padding: 11px 15px!important;
    width: 30%;
    text-align: left;
  }
  td {
    padding: 11px 15px!important;
    input,textarea {
      background: #faf9f6;
      border: 2px solid #e8e8e8;
      width: 100%;
      padding: 5px;
    }
    .notes {
      color: #666;
      margin: 10px 0 0;
    }
  }
  tr.required {
    input,textarea {
      border: 2px solid #fbd193 !important;
      background: #fffaf0 !important;
    }
  }
  span.required {
    font-size: 1.2rem;
    font-weight: bold;
    letter-spacing: 0.15em;
    line-height: 1;
    color: #fff;
    background: #c94941;
    display: inline-block;
    padding: 5px 6px 5px 8px;
    margin-left: 15px;
  }
}
@media screen and (max-width: $display-width-s){
  /* =================================
    都道府県検索
  ================================= */
  .searchPrefectures {
    border-radius: 0;
    padding: 10px;
    width: 100%;
    margin: 0;
    &__ttl {
      font-size: 1.6rem;
      padding: 5px 0 14px;
    }
    &__subTtl {
      font-size: 1.5rem;
      margin: 0 0 6px;
    }
    &__item {
      padding: 10px;
      background: #fff;
      display: block;
      max-width: auto;
      max-width: initial;
    }
    &__block {
      width: 100%;
      &__list {
        li {
          width: calc(25% - 1px);
          border: none;
          font-size: 1.2rem;
          line-height: 1;
          margin: 1px 0 0 1px;
          a {
            border: none;
            padding: 4px 0;
            text-decoration: none;
            background: #f1f1f1;
          }
          &:nth-child(4n + 1) {
            margin-left: 0;
          }
          &:nth-child(5n + 1) {
            margin-left: 1px;
          }
        }
      }
      > li + li {
        margin: 15px 0 0;
      }
      & + .searchPrefectures__block {
        margin: 15px 0 0;
      }
    }
  }
  /* =================================
    toppage
  ================================= */
  .mv {
    height: 100%;
    background: url(/images/mv_bg_sp.png) no-repeat top center #f4f6f5;
    background-size: auto 236px;
    background-position: center top;
    padding: 0;
    &__inner {
      display: block;
    }
    &Catch {
      width: 100%;
      min-height: 200px;
      background: url(/images/mv_catch_bg.png) no-repeat right 40px top;
      background-size: 200px auto;
      position: relative;
      &__text {
        position: absolute;
        top: initial;
        bottom: 70px;
        right: 60px;
        transform: translateY(0);
        h1 {
          font-size: 1.7rem;
        }
      }
      &__btn {
        width: 100%;
        bottom: 12px;
        &.btn--pink a {
          width: 90%;
          font-size: 1.6rem;
          padding: 15px 0;
          margin: 0 auto;
        }
      }
    }
    .searchPrefectures {
      &__block {
        &__list {
          li {
            margin: 1px 0 0 1px!important;
            &:nth-child(4n + 1) {
              margin-left: 0;
            }
            &:nth-child(5n + 1) {
              margin-left: 1px;
            }
          }
        }
      }
    }
  }
  .topFeature {
    background: none;
    padding: 0 0 20px;
    position: relative;
    top: 0;
    z-index: 10;
  }
  .topSecCont {
    background: #fff;
    padding: 15px 0 5px;
  }
  .secCont  + .secCont {
    margin: 25px 0 0;
  }
  /*campaignList*/
  .campaignList {
    display: block;
    &__item {
      width: initial;
      padding: 0 35px;
      & + .campaignList__item {
        margin: 0;
      }
    }
    &__ttl {
      font-size: 1.6rem;
      padding: 11px 0 14px;
      margin: 4px 0 0;
    }
    &__summary {
      font-size: 1.6rem;
      margin: 10px 0 0;
    }
  }
  /*secCheckpoint*/
  .secCheckpoint {
    .topSecCont {
      padding: 10px;
      margin-bottom: 0;
      border-radius: 0;
      .topSecWrap__inner {
        border-radius: 6px;
        padding: 24px 10px 15px;
        &::before {
          content: "";
          width: 98%;
          height: 17px;
          top: -5px;
          background: url(/images/point_deco_ring.png) repeat-x top left;
          background-size: contain;
        }
      }
    }
    .checkpoint {
      &__head {
        &__ttl {
          font-size: 1.6rem;
          margin-right: 0;
          padding: 11px 0 11px 15px;
          position: relative;
          left: -15px;
          &::before {
            border-width: 0 5px 5px 0;
            top: initial;
            bottom: -5px;
          }
          &::after {
            right: -45px;
          }
        }
        &__desc {
          font-size: 1.4rem;
          line-height: 1.5714;
          padding: 10px 7px 0;
        }
      }
      &__list {
        background: none;
        margin: 17px 0 0;
        &__l {
          width: calc(50% - 5px);
        }
        &__r {
          width: calc(50% - 5px);
          margin-left: 10px;
        }
        li {
          background: url(/images/bg_checkpoint_line1.png) no-repeat center center;
          background-size: contain;
          padding: 10px 0 10px;
          &.line2 {
            background: url(/images/bg_checkpoint_line2.png) no-repeat center center;
            background-size: contain;
          }
          a {
            font-size: 1.4rem;
            padding: 0;
            span {
              font-size: 1.1rem;
              margin: 3px 0 0;
            }
          }
          & + li {
            margin: 5px 0 0;
          }
        }
        &::after {
          content: "";
          width: 138px;
          height: 82px;
          position: absolute;
          bottom: 0;
          right: -10px;
        }
      }
    }
  }
  /*secReport*/
  .report__list {
    display: block;
    padding: 10px 10px 0;
    margin-bottom: 0;
    &__item {
      width: 100%;
      background: #fcfdd2;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row-reverse;
      padding: 5px;
      &:nth-child(2n) {
        margin-left: 0;
      }
      &:nth-child(n + 3) {
        margin-top: 0;
      }
      & + .report__list__item {
        margin-top: 10px;
      }
    }
    .reportTtl {
      font-size: 1.4rem;
      padding: 0;
      width: calc(100% - 83px);
      &::before {
        margin-right: 0;
        position: absolute;
        right: 10px;
      }
    }
    .summary {
      display: block;
      padding: 0;
      img {
        width: 70px;
        margin-right: 13px;
      }
      p {
        display: none;
      }
    }
  }
  /*secBasic*/
  .basicBox {
    padding: 15px 10px 0;
    display: block;
    &__ttl {
      font-size: 1.6rem;
      margin-bottom: 8px;
      &::before {
        content: "";
        width: 15px;
        height: 12px;
        margin-right: 5px;
      }
    }
    &__item {
      width: 100%;
      margin-bottom: 30px;
      &:nth-child(2n) {
        margin-left: 0;
      }
    }
  }
  .basicBox__btn {
    margin-top: 10px;
    a {
      font-size: 1.5rem;
      padding: 15px 24px;
      &::before {
        content: "";
        width: 19px;
        height: 14px;
        margin-right: 10px;
      }
    }
  }
  /* =================================
    記事一覧
  ================================= */
  .articleListWrap {
    .list--arrow {
      &__item {
        .summary {
          a {
            font-size: 1.4rem;
          }
        }
      }
    }
  }
  /* =================================
    記事詳細
  ================================= */
  .article__detail {
    h1 {
      font-size: 1.8rem;
      padding: 18px 6px;
      margin-bottom: 15px;
      &::before {
        height: 7px;
      }
      &::after {
        height: 7px;
      }
    }
    h2 {
      padding: 12px 14px 9px 14px;
      margin: 30px 0 15px;
      &::before {
        height: 2px;
        top: 3px;
      }
    }
    h3 {
      padding: 10px 12px;
      margin: 30px 0 20px;
    }
    h4 {
      font-size: 1.5rem;
      background: url(/images/icon_circle.png) no-repeat left top;
      background-size: 13px 13px;
      padding: 0 0 0 22px;
      margin: 30px 0 20px;
    }
    h5 {
      font-size: 1.4rem;
    }
    p {
      margin: 15px 0 0;
    }
    img {
      display: block;
      margin: 20px auto 10px;
    }
    > ol {
      padding: 15px;
      margin: 10px 0 25px;
      li {
        font-size: 1.4rem;
        padding-left: 23px;
        &::before {
          font-size: 1.4rem;
          padding: 2px 5px;
        }
        & + li {
          margin: 17px 0 0;
        }
      }
    }
    > ul:not([class]) {
      padding: 15px;
      margin: 10px 0 25px;
      li {
        font-size: 1.4rem;
        & + li {
          margin: 17px 0 0;
        }
      }
    }
    table { 
      margin: 30px 0 20px;
      overflow-x: auto;
      white-space: nowrap;
      border: none;
      display: block;
      caption {
        font-size: 1.6rem;
        margin-bottom: 6px;
      }
      th {
        font-size: 1.4rem;
        padding: 5px 8px;
      }
      td {
        font-size: 1.4rem;
        line-height: 1.25;
        padding: 5px 8px;
      }
    }
    .searchPrefectures {
      margin: 38px 0 10px;
      &::before {
        content: none;
      }
      &::after {
        content: none;
      }
      &__block {
        &__list {
          li {
            margin: 1px 0 0 1px;
            &:nth-child(5n + 1) {
              margin-left: 0!important;
            }
            &:nth-child(6n + 1) {
              margin-left: 0!important;
            }
            &:nth-child(6) {
              margin-left: 1px!important;
            }
            &:nth-child(7) {
              margin-left: 1px!important;
            }
            &:nth-child(4n + 1) {
              margin-left: 0;
            }
          }
        }
      }
      .searchForm {
        display: none;
      }
    }
  }
  .asideCont {
    margin: 30px 0;
    .asideCont__ttl {
      font-size: 1.8rem;
      padding: 0 0 8px 4px;
    }
    &.recommend {
      .asideCont__ttl {
        &::before {
          width: 25px;
          height: 20px;
          margin-right: 7px;
        }
      }
    }
    &.related {
      .asideCont__ttl {
        &::before {
          width: 25px;
          height: 20px;
          margin-right: 7px;
        }
      }
    }
    &.new {
      .asideCont__ttl {
        &::before {
          width: 25px;
          height: 20px;
          margin-right: 7px;
        }
      }
    }
  }
/*キャンペーン情報*/
.tmBox {
  border: 3px solid #e1f2f2;
  padding: 8px 10px;
  margin: 20px 0;
  border-radius: 5px;
  display: block;
  .thumb {
    width: 100%;
    margin: 0 0 15px;
    img {
      margin: 0 auto!important;
    }
  }
  .tmSummary {
    width: 100%;
    .tm__ttl {
      border-bottom: 2px solid #eee;
      a {
        font-size: 1.6rem;
        line-height: 22px;
      }
    }
    .tm__text {
      font-size: 1.4rem;
      margin: 12px 0 8px;
    }
    .tm__campaign {
      span {
        padding: 3px;
      }
      p {
        padding: 15px 10px 10px;
      }
    }
  }
}
/*よく読まれています*/
.relatedSingle {
  background: #fefbe0;
  padding: 25px 25px 15px;
  position: relative;
  border-radius: 2px;
  margin: 30px 0;
  border: 1px solid #eee;
  box-shadow: 1px 1px 5px #555;
  line-height: 1.5;
  position: relative;
  .related__ttl {
    font-size: 1.6rem;
    color: #fff;
    display: inline-block;
    position: absolute;
    top: -20px;
    left: 25px;
    padding: 0.2em 1em;
    background: #66B4D5;
    border-radius: 10px;
    &::after {
      content: "";
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      left: 20px;
      bottom: -10px;
      border: 5px solid transparent;
      border-top: 5px solid #66B4D5;
    }
  }
  &__in {
    display: flex;
    .thumb {
      width: 110px;
      margin: 0 18px 0 0;
      img {
        margin: 0;
      }
    }
    time {
      font-size: 1.5rem;
      display: block;
    }
  }
}

  /* =================================
    サロン カード
  ================================= */
  .salonCard {
    &__item {
      border: 1px solid #c1dfde;
      border-radius: 0;
      padding: 0;
      & + .salonCard__item {
        margin: 25px 0 0; 
      }
      &__inner {
        border-radius: 0;
        border: none;
        padding: 13px 10px 2px;
      }
    }
    &__ttl {
      font-size: 1.6rem!important;
      padding: 10px 0 10px 53px!important;
      span {
        font-size: 1.4rem;
        margin: 0 0 8px;
      }
      &::before {
        width: 42px!important;
        height: 42px!important;
        top: -4px!important;
      }
    }
    &__left {
      width: 100%;
      margin: 0;
      img {
        display: block;
        margin: 0 auto 10px;
      }
    }
    &__right {
      width: 100%;
      margin: 10px 0 0;
      .rating {
        margin: 0 0 10px;
        border: 1px solid #e1f2f2;
        display: table;
        th{
          font-size: 1.4rem;
          padding: 5px 0;
          border: none;
        }
        td {
          border: 1px solid #e1f2f2;
          padding: 5px;
        }
        .point {
          display: block;
          text-align: center;
          .pointText {
            font-size: 2rem;
            margin: 3px 0 0;
            display: inline-block;
          }
        }
        .ranking {
          font-size: 1.2rem;
          margin-top: 0;
        }
        .buzz {
          a {
            font-size: 2rem;
            padding: 0 0 0 30px;
          }
        }
      }
      .graph {
        border: 1px solid #e1f2f2;
        border-top: none;
        margin: -12px 0 10px;
        padding: 12px 10px;
        table {
          .graphNum {
            font-size: 1.3rem;
            &::before {
              font-size: 1.4rem;
            }
          }
          .mater {
            width: 100%;
            padding: 0 10px 10px 10px;
          }
          .ratingNum {
            font-size: 1.6rem;
          }
        }
      }
    }
    .info {
      padding: 10px 10px 0;
      .store {
        font-size: 1.2rem;
        > span {
          margin-left: 5px;
        }
        .num {
          font-size: 1.6rem;
        }
      }
      .fix {
        display: flex;
        align-items: flex-start;
        padding-top: 8px;
        margin-top: 8px;
        li {
          font-size: 1.1rem;
          padding: 8px 0;
          width: calc(33.333333% - 3.333333px);
          margin-bottom: 5px;
          &::after {
            border-width: 7px 7px 0 0;
          }
          & + li {
            margin-top: 0;
            margin-left: 5px;
          }
        }
      }
    }
    .feature {
      li {
        width: calc(16.666666% - 7.5px);
        height: 48px;
        font-size: 1rem;
        margin: 0 0 9px 9px;
        &::after {
          content: "";
          border-style: solid;
          border-width: 9px 9px 0 0;
        }
      }
    }
    .cost {
      margin: 10px 0 0;
      font-size: 1.4rem;
      border: none;
      display: table;
      th {
        padding: 7px 0;
      }
      td {
        padding: 7px 0;
        em {
          font-size: 1.6rem;
        }
      }
    }
    .campaign {
      margin: 10px 0 0;
      &Ttl {
        &::before {
          width: 20px;
          height: 20px;
        }
        &::after {
          width: 20px;
          height: 20px;
        }
        span {
          position: relative;
          padding: 8px 5px;
        }
      }
      &Text {
        font-size: 1.4rem;
        padding: 12px 5px;
      }
    }
    &__summary {
      font-size: 1.4rem;
      font-weight: 600;
      margin: 10px 0 0;
    }
  }
  #top {
    .secRanking {
      .salonCard {
        &__left {
          width: 100%;
          margin: 0;
        }
        &__right {
          width: 100%;
          .rating {
            .point {
              &Text {
                font-size: 2rem;
              }
            }
          }
          .buzz {
            a {
              font-size: 2rem;
            }
          }
        }
      }
    }
  }
  /* =================================
    サロン ランキング
  ================================= */
  .salonRanking {
    .rankingTtl {
      margin-bottom: 30px;
    }
  }
  /* =================================
    サロン レポート
  ================================= */
  .report__head {
    padding: 10px 10px 20px;
    &::before {
      height: 6px;
    }
    &::after {
      height: 6px;
    }
    .report__ttl {
      font-size: 1.8rem;
      line-height: 1.333333;
      padding-left: 0;
      margin: 0 0 5px;
      &::before {
        width: 112px;
        height: 44px;
        display: block;
        position: static;
      }
    }
    .report__thumb {
      border: 5px solid #fff;
      box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
    }
  }
  .report__info {
    margin: 25px 0 0;
    .reporter {
      overflow: hidden;
    }
    .reporterTtl {
      margin: 0 0 13px;
      width: 576px;
      img {
        width: auto;
        height: 40px;
      }
    }
    .reporter__2col {
      .reporter__thumb {
        width: 50px;
        margin-right: 10px;
      }
      .reporter__profile {
        width: calc(100% - 60px);
        .name {
          font-size: 1.6rem;
          margin: 0 0 3px;
        }
        .text {
          line-height: 1.57142;
        }
      }
    }
  }
  .menu {
    margin: 17px 0 0;
    &Ttl {
      padding-left: 5px;
      height: 32px;
      img {
        width: auto;
        height: 100%;
      }
    }
    &__list {
      padding: 12px;
      display: block;
    }
    &__item {
      display: flex;
      &Ttl {
        font-size: 1.8rem;
        margin: 0;
        width: 95px;
      }
      &Text {
        font-size: 1.5rem;
        width: calc(100% - 95px);
      }
      &.course {
        width: 100%;
      }
      &.set {
        width: 100%;
      }
      &.price {
        width: 100%;
        align-items: flex-end;
        .menu__itemText {
          font-size: 2rem;
        }
      }
      & + .menu__item {
        border-left: none;
        border-top: 1px solid #ded5a3;
        padding-left: 0;
        margin-left: 0;
        padding-top: 14px;
        margin-top: 14px;
        &::before {
          border-width: 6px 6px 0 6px;
          border-color: #ded5a3 transparent transparent transparent;
          top: 3px;
          left: 50%;
          transform: translate(0,-50%);
        }
        &::after {
          border-width: 6px 6px 0 6px;
          border-color: #fff transparent transparent transparent;
          top: 1px;
          left: 50%;
          transform: translate(0,-50%);
        }
      }
    }
  }
  /* =================================
    contact
  ================================= */
  .contactForm {
    tbody {
      display: block;
    }
    tr {
      display: block;
    }
    th {
      width: 100%;
      display: block;
    }
    td {
      display: block;
      input,textarea {
        background: #faf9f6;
        border: 2px solid #e8e8e8;
        width: 100%;
        padding: 5px;
      }
      .notes {
        color: #666;
        margin: 10px 0 0;
      }
    }
    tr.required {
      input,textarea {
        border: 2px solid #fbd193 !important;
        background: #fffaf0 !important;
      }
    }
    span.required {
      font-size: 1.2rem;
      font-weight: bold;
      letter-spacing: 0.15em;
      line-height: 1;
      color: #fff;
      background: #c94941;
      display: inline-block;
      padding: 5px 6px 5px 8px;
      margin-left: 15px;
    }
  }
}